/* ==========================================================================
   Reset
   ========================================================================== */

/**
 * Remove anchor tex-decoration
 */ 

a {
	text-decoration: none;
}

/**
 * Remove list-style
 */ 

ol, ul {
	list-style: none;
}

html, body, div, span, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, a, em, img, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, article, footer, header, nav, section, main {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}

h1, h2, h3, h4, h5, h6, p, a, ul {
	font-size: 1em;
}